<template>
	<div>
		<el-row>
			<el-button v-for="(btn, index) in list" :key="index" :class="{'active-btn': btnIndex == btn.value}"
				@click="btnClick(btn.value)">{{btn.name}}</el-button>
		</el-row>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				list: [],
				btnIndex: 0
			}
		},
		props: {
			btnList: {
				type: Array,
				default: () => {
					return []
				}
			},
		},
		created() {
			this.list = this.btnList
		},
		methods: {
			btnClick(index) {
				this.btnIndex = index
				this.$emit('btnClick', this.btnIndex)
			}
		},
	}
</script>

<style scoped>
	.active-btn {
		background-color: #4a9efc;
		color: #FFFFFF;
		border: #4a9efc;
	}
</style>
