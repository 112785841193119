<template>
  <div class="bg-white" style="height: calc(100vh - 100px);">
    <el-tabs type="border-card">
      <el-tab-pane style="min-height: 100%;">
        <span slot="label">
          <i class="el-icon-user" />
          用户管理
        </span>
        <button-list :btnList="userList" @btnClick="userClick" />
        <template>
          <div v-show="userIndex == 0">
            <div class="margin-top flex aligin-items-center flex-wrap-wrap width-100">
              <div class="width-25">
                时间
                <el-select v-model="timeValue" placeholder="全部" size="mini">
                  <el-option v-for="item in timeList" :key="item.value" :label="item.label" :value="item.value"></el-option>
                </el-select>
              </div>
              <div class="width-25">
                标签
                <el-select v-model="value" placeholder="不限" size="mini">
                  <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value"></el-option>
                </el-select>
              </div>
              <div class="width-25">
                入库类型
                <el-select v-model="value" placeholder="不限" size="mini">
                  <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value"></el-option>
                </el-select>
              </div>
              <div class="width-25">
                流失类型
                <el-select v-model="value" placeholder="不限" size="mini">
                  <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value"></el-option>
                </el-select>
              </div>
              <div class="width-25 margin-top">
                来源
                <el-select v-model="value" placeholder="不限" size="mini">
                  <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value"></el-option>
                </el-select>
              </div>
            </div>

            <div class="margin-top padding border-grey">
              <div class="text-black text-bold text-lg">客户数量趋势图</div>
              <div class="margin-top-sm tab-color">
                <el-tabs v-model="activeName">
                  <el-tab-pane label="入库客户数" name="first"><echart width="100vh" :options="eOptions" id="eOptions"></echart></el-tab-pane>
                  <el-tab-pane label="流失客户数" name="second">配置管理</el-tab-pane>
                  <el-tab-pane label="客户总数" name="third">角色管理</el-tab-pane>
                </el-tabs>
              </div>
            </div>
            <div class="margin-top padding border-grey">
              <div class="flex justify-content-between aligin-items-center">
                <span class="flex aligin-items-center">
                  <span class="text-black text-bold text-lg">客户数量明细表</span>
                  <el-tooltip class="item" effect="dark" content="提示文字" placement="right"><i class="el-icon-question el-icon--right text-grey cursor" /></el-tooltip>
                </span>
                <el-checkbox v-model="checked">展示无数据项</el-checkbox>
              </div>
              <div class="margin-top-sm">
                <el-table
                  :data="customerData"
                  style="width: 100%"
                  :default-sort="{ prop: 'date', order: 'descending' }"
                  :header-cell-style="{ background: '#eef1f6', color: '#606266' }"
                >
                  <el-table-column prop="date" label="时间" sortable align="center"></el-table-column>
                  <el-table-column prop="name" label="入库客户" sortable align="center"></el-table-column>
                  <el-table-column prop="name" label="流失客户" sortable align="center"></el-table-column>
                  <el-table-column prop="name" label="总客户数" sortable align="center"></el-table-column>
                </el-table>
              </div>
            </div>
          </div>
        </template>

        <template>
          <div v-show="userIndex == 1">
            <div class="margin-top flex aligin-items-center flex-wrap-wrap width-100">
              <div class="width-25">
                客户创建时间
                <el-select v-model="timeValue" placeholder="全部" size="mini">
                  <el-option v-for="item in timeList" :key="item.value" :label="item.label" :value="item.value"></el-option>
                </el-select>
              </div>
              <div class="width-25">
                标签
                <el-select v-model="value" placeholder="不限" size="mini">
                  <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value"></el-option>
                </el-select>
              </div>
            </div>
            <div class="margin-top padding border-grey">
              <div class="text-black text-bold text-lg">客户最近联系时间分布图</div>
              <div class="margin-top-sm tab-color">
                <el-tabs v-model="activeName">
                  <el-tab-pane label="联系时间" name="first"><echart width="100vh" :options="customerOptions" id="customerOptions"></echart></el-tab-pane>
                  <el-tab-pane label="联系次数" name="second">配置管理</el-tab-pane>
                </el-tabs>
              </div>
              <div class="margin-top padding border-grey">
                <div class="text-black text-bold text-lg">客户最近联系时间统计表</div>
                <div class="margin-top-sm">
                  <el-table :data="customerData" style="width: 100%" :header-cell-style="{ background: '#eef1f6', color: '#606266' }" show-summary>
                    <el-table-column align="center" prop="date">
                      <template slot="header">
                        <span>联系时间</span>
                        <el-tooltip class="item" effect="dark" content="提示文字" placement="top"><i class="el-icon-question text-grey cursor el-icon--right" /></el-tooltip>
                      </template>
                    </el-table-column>
                    <el-table-column prop="name" label="客户数" align="center"></el-table-column>
                    <el-table-column prop="name" label="百分比" align="center"></el-table-column>
                  </el-table>
                </div>
                <div>注：···</div>
              </div>
            </div>
          </div>
        </template>
        <template>
          <div v-show="userIndex == 2">
            <div class="margin-top flex aligin-items-center flex-wrap-wrap width-100">
              <div class="width-25">
                时间
                <el-select v-model="timeValue" placeholder="全部" size="mini">
                  <el-option v-for="item in timeList" :key="item.value" :label="item.label" :value="item.value"></el-option>
                </el-select>
              </div>
              <div class="width-25">
                标签
                <el-select v-model="value" placeholder="不限" size="mini">
                  <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value"></el-option>
                </el-select>
              </div>
            </div>
          </div>
        </template>
        <template v-if="userIndex == 3">
          <div class="width-100 margin-top">
            <div class="flex width-100 border-grey aligin-items-center">
              <span class="bg-light-gray padding-sm text-center" style="width: 18%;">员工/部门</span>
              <span class="padding-xs margin-left">
                <span class="padding-xs margin-right border-radius bg-blue">筛选</span>
                <span class="padding-xs margin-right border-radius">不限</span>
              </span>
            </div>
            <div class="flex width-100 border-grey aligin-items-center">
              <span class="bg-light-gray padding-sm text-center" style="width: 18%;">统计时间</span>
              <span class="padding-xs margin-left">
                <span class="padding-xs margin-right border-radius bg-blue">今天</span>
                <span class="padding-xs margin-right border-radius">昨天</span>
                <span class="padding-xs margin-right border-radius">最近7天</span>
                <span class="padding-xs margin-right border-radius">最近30天</span>
                <span class="padding-xs margin-right border-radius">自定义时间</span>
              </span>
            </div>
            <div class="flex width-100 border-grey aligin-items-center">
              <span class="bg-light-gray padding-sm text-center" style="width: 18%;">统计时间</span>
              <span class="padding-xs margin-left">
                <el-input placeholder="请输入客户/公司" v-model="input2" size="mini"><el-button slot="append" icon="el-icon-search"></el-button></el-input>
              </span>
            </div>
          </div>
          <div class="margin-top-sm">
            <el-table :data="customerData" style="width: 100%" :header-cell-style="{ background: '#eef1f6', color: '#606266' }">
              <el-table-column prop="date" label="创建时间" align="center"></el-table-column>
              <el-table-column prop="name" label="客户" align="center"></el-table-column>
              <el-table-column prop="name" label="职务" align="center"></el-table-column>
              <el-table-column prop="name" label="公司" align="center"></el-table-column>
            </el-table>
            <div>注：···</div>
          </div>
        </template>
        <template>
          <div v-show="userIndex == 4">
            <div class="width-100 margin-top">
              <div class="flex width-100 border-grey aligin-items-center">
                <span class="bg-light-gray padding-sm text-center" style="width: 18%;">时间</span>
                <span class="padding-xs margin-left">
                  <span class="padding-xs margin-right border-radius bg-blue">本月</span>
                  <span class="padding-xs border-radius">上月</span>
                </span>
                <el-divider direction="vertical"></el-divider>
                <el-date-picker size="mini" v-model="dateValue" type="daterange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期"></el-date-picker>
              </div>
            </div>

            <div class="margin-top padding border-grey">
              <div class="text-black text-bold text-lg">热门渠道Top 10</div>
              <echart width="100vh" :options="qudaoOptions" id="qudaoOptions"></echart>
            </div>

            <div class="margin-top padding border-grey">
              <div class="flex justify-content-between aligin-items-center">
                <span class="flex aligin-items-center"><span class="text-black text-bold text-lg">客户数量明细表</span></span>
              </div>
              <div class="margin-top-sm">
                <el-table
                  :data="customerData"
                  style="width: 100%"
                  :default-sort="{ prop: 'date', order: 'descending' }"
                  :header-cell-style="{ background: '#eef1f6', color: '#606266' }"
                >
                  <el-table-column prop="date" label="客户来源" align="center"></el-table-column>
                  <el-table-column prop="name" label="访问人数" sortable align="center"></el-table-column>
                  <el-table-column prop="name" label="导入客户数" sortable align="center"></el-table-column>
                  <el-table-column prop="name" label="访客转化率" sortable align="center"></el-table-column>
                </el-table>
              </div>
            </div>
          </div>
        </template>
        <template v-if="userIndex == 5"></template>
        <template>
          <div v-show="userIndex == 6">
            <div class="width-100 margin-top">
              <div class="flex width-100 border-grey aligin-items-center">
                <span class="bg-light-gray padding-sm text-center" style="width: 18%;">创建时间</span>
                <div class="margin-left">
                  <el-date-picker size="mini" v-model="dateValue" type="daterange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期"></el-date-picker>
                </div>
              </div>
              <div class="flex width-100 border-grey aligin-items-center">
                <span class="bg-light-gray padding-sm text-center" style="width: 18%;">员工/部门</span>
                <span class="padding-xs margin-left">
                  <span class="padding-xs margin-right border-radius bg-blue">筛选</span>
                  <span class="padding-xs margin-right border-radius">不限</span>
                </span>
              </div>
              <div class="flex width-100 border-grey aligin-items-center">
                <span class="bg-light-gray padding-sm text-center" style="width: 18%;">来源</span>
                <span class="padding-xs margin-left"><span class="padding-xs margin-right border-radius bg-blue">全部</span></span>
              </div>
            </div>

            <div class="margin-top padding border-grey">
              <div class="text-black text-bold text-lg">来源转化Top 10</div>
              <echart width="100vh" :options="fromOptions" id="fromOptions"></echart>
            </div>
            <div class="margin-top padding border-grey">
              <div class="text-black text-bold text-lg">员工转化Top 10</div>
              <echart width="100vh" :options="staffOptions" id="staffOptions"></echart>
            </div>
            <div class="margin-top padding border-grey">
              <div class="flex justify-content-between aligin-items-center">
                <span class="flex aligin-items-center">
                  <span class="text-black text-bold text-lg">数据明细</span>
                  <el-tooltip class="item" effect="dark" content="提示文字" placement="right"><i class="el-icon-question el-icon--right text-grey cursor" /></el-tooltip>
                </span>
              </div>
              <div class="margin-top-sm">
                <el-tabs v-model="activeName" type="card" @tab-click="handleClick">
                  <el-tab-pane label="来源统计" name="first">
                    <div class="margin-top-sm">
                      <el-table
                        :data="customerData"
                        style="width: 100%"
                        :default-sort="{ prop: 'date', order: 'descending' }"
                        :header-cell-style="{ background: '#eef1f6', color: '#606266' }"
                      >
                        <el-table-column prop="date" label="时间" sortable align="center"></el-table-column>
                        <el-table-column prop="name" label="入库客户" sortable align="center"></el-table-column>
                        <el-table-column prop="name" label="流失客户" sortable align="center"></el-table-column>
                        <el-table-column prop="name" label="总客户数" sortable align="center"></el-table-column>
                      </el-table>
                    </div>
                  </el-tab-pane>
                  <el-tab-pane label="员工统计" name="second">员工统计</el-tab-pane>
                </el-tabs>
              </div>
            </div>
          </div>
        </template>
      </el-tab-pane>
      <el-tab-pane style="min-height: 87vh;">
        <span slot="label">
          <i class="el-icon-data-line" />
          销售过程管理
        </span>
        <button-list :btnList="salesList" @btnClick="salesClick" />

        <template>
          <div v-show="salesIndex == 0">
            <div class="width-100 margin-top">
              <div class="flex width-100 border-grey aligin-items-center">
                <span class="bg-light-gray padding-sm text-center" style="width: 18%;">员工/部门</span>
                <span class="padding-xs margin-left">
                  <span class="padding-xs margin-right border-radius bg-blue">筛选</span>
                  <span class="padding-xs margin-right border-radius">不限</span>
                </span>
              </div>
              <div class="flex width-100 border-grey aligin-items-center">
                <span class="bg-light-gray padding-sm text-center" style="width: 18%;">创建时间</span>
                <span class="padding-xs margin-left">
                  <span class="padding-xs margin-right border-radius bg-blue">今天</span>
                  <span class="padding-xs margin-right border-radius">昨天</span>
                  <span class="padding-xs margin-right border-radius">本周</span>
                  <span class="padding-xs margin-right border-radius">上周</span>
                  <span class="padding-xs margin-right border-radius">按月查看</span>
                </span>
              </div>
            </div>
            <div class="margin-top padding border-grey">
              <div class="flex justify-content-between aligin-items-center">
                <span class="flex aligin-items-center"><span class="text-black text-bold text-lg">工作效率统计表</span></span>
              </div>
              <div class="margin-top padding border-grey">
                <div class="text-black text-bold text-lg">客户最近联系时间统计表</div>
                <div class="margin-top-sm">
                  <el-table :data="customerData" style="width: 100%" :header-cell-style="{ background: '#eef1f6', color: '#606266' }">
                    <el-table-column prop="name" label="姓名" align="center"></el-table-column>
                    <el-table-column align="center" prop="date">
                      <template slot="header">
                        <span>入库客户</span>
                        <el-tooltip class="item" effect="dark" content="提示文字" placement="top"><i class="el-icon-question text-grey cursor el-icon--right" /></el-tooltip>
                      </template>
                    </el-table-column>
                    <el-table-column align="center" prop="date">
                      <template slot="header">
                        <span>联系客户</span>
                        <el-tooltip class="item" effect="dark" content="提示文字" placement="top"><i class="el-icon-question text-grey cursor el-icon--right" /></el-tooltip>
                      </template>
                    </el-table-column>
                    <el-table-column
                      align="center"
                      prop="date"
                      label="电话"
                      column-key="date"
                      :filters="[
                        { text: '2016-05-01', value: '2016-05-01' },
                        { text: '2016-05-02', value: '2016-05-02' },
                        { text: '2016-05-03', value: '2016-05-03' },
                        { text: '2016-05-04', value: '2016-05-04' }
                      ]"
                      :filter-method="filterHandler"
                    />
                    <el-table-column prop="name" label="QQ" align="center"></el-table-column>
                    <el-table-column align="center" prop="date">
                      <template slot="header">
                        <span>微信</span>
                        <el-tooltip class="item" effect="dark" content="提示文字" placement="top"><i class="el-icon-question text-grey cursor el-icon--right" /></el-tooltip>
                      </template>
                    </el-table-column>
                    <el-table-column align="center" prop="date">
                      <template slot="header">
                        <span>短信</span>
                        <el-tooltip class="item" effect="dark" content="提示文字" placement="top"><i class="el-icon-question text-grey cursor el-icon--right" /></el-tooltip>
                      </template>
                    </el-table-column>
                    <el-table-column
                      align="center"
                      prop="date"
                      label="邮件"
                      column-key="date"
                      :filters="[
                        { text: '2016-05-01', value: '2016-05-01' },
                        { text: '2016-05-02', value: '2016-05-02' },
                        { text: '2016-05-03', value: '2016-05-03' },
                        { text: '2016-05-04', value: '2016-05-04' }
                      ]"
                      :filter-method="filterHandler"
                    />
                    <el-table-column prop="name" label="拜访" align="center"></el-table-column>
                    <el-table-column prop="name" label="跟进记录数" align="center"></el-table-column>
                    <el-table-column prop="name" label="总次数" align="center"></el-table-column>
                  </el-table>
                </div>
                <div>
                  注：···
                  <span class="text-blue">联系次数规则</span>
                </div>
              </div>
            </div>
          </div>
        </template>
        <template>
          <div v-show="salesIndex == 1">
            <div class="width-100 margin-top">
              <div class="flex width-100 border-grey aligin-items-center">
                <span class="bg-light-gray padding-sm text-center" style="width: 18%;">时间</span>
                <span class="padding-xs margin-left">
                  <span class="padding-xs margin-right border-radius bg-blue">今天</span>
                  <span class="padding-xs margin-right border-radius">昨天</span>
                  <span class="padding-xs margin-right border-radius">本周</span>
                  <span class="padding-xs margin-right border-radius">上周</span>
                  <span class="padding-xs margin-right border-radius">按月查看</span>
                </span>
              </div>
              <div class="flex width-100 border-grey aligin-items-center">
                <span class="bg-light-gray padding-sm text-center" style="width: 18%;">通话方式</span>
                <span class="padding-xs margin-left">
                  <span class="padding-xs margin-right border-radius bg-blue">筛选</span>
                  <span class="padding-xs margin-right border-radius">不限</span>
                </span>
              </div>
              <div class="flex width-100 border-grey aligin-items-center">
                <span class="bg-light-gray padding-sm text-center" style="width: 18%;">分类</span>
                <span class="padding-xs margin-left">
                  <span class="padding-xs margin-right border-radius bg-blue">筛选</span>
                  <span class="padding-xs margin-right border-radius">不限</span>
                </span>
              </div>
              <div class="flex width-100 border-grey aligin-items-center">
                <span class="bg-light-gray padding-sm text-center" style="width: 18%;">通话时长</span>
                <span class="padding-xs margin-left">
                  <span class="padding-xs margin-right border-radius bg-blue">筛选</span>
                  <span class="padding-xs margin-right border-radius">不限</span>
                </span>
              </div>
              <div class="flex width-100 border-grey aligin-items-center">
                <span class="bg-light-gray padding-sm text-center" style="width: 18%;">员工/部门</span>
                <span class="padding-xs margin-left">
                  <span class="padding-xs margin-right border-radius bg-blue">筛选</span>
                  <span class="padding-xs margin-right border-radius">不限</span>
                </span>
              </div>
            </div>
            <div class="margin-top-sm">
              <el-radio-group v-model="dateTadio" size="mini">
                <el-radio-button label="按日期查看"></el-radio-button>
                <el-radio-button label="按员工查看"></el-radio-button>
              </el-radio-group>
            </div>
            <div class="margin-top padding border-grey">
              <span class="flex aligin-items-center">
                <span class="text-black text-bold text-lg">日期趋势图</span>
                <el-tooltip class="item" effect="dark" content="提示文字" placement="right"><i class="el-icon-question el-icon--right text-grey cursor" /></el-tooltip>
              </span>
              <div class="margin-top-sm tab-color">
                <el-tabs v-model="activeName">
                  <el-tab-pane label="接通率" name="first"><echart width="100vh" :options="dateOptions" id="dateOptions"></echart></el-tab-pane>
                  <el-tab-pane label="通话时长" name="second">配置管理</el-tab-pane>
                  <el-tab-pane label="拨打次数" name="third">角色管理</el-tab-pane>
                  <el-tab-pane label="平均通话时长" name="fourth">定时任务补偿</el-tab-pane>
                  <el-tab-pane label="联系人数量" name="fourth">定时任务补偿</el-tab-pane>
                </el-tabs>
              </div>
            </div>
            <div class="margin-top padding border-grey">
              <div class="flex justify-content-between aligin-items-center">
                <span class="flex aligin-items-center"><span class="text-black text-bold text-lg">电话联系明细表</span></span>
                <el-checkbox v-model="checked">展示无数据项</el-checkbox>
              </div>
              <div class="margin-top-sm">
                <el-table
                  :data="customerData"
                  style="width: 100%"
                  :default-sort="{ prop: 'date', order: 'descending' }"
                  :header-cell-style="{ background: '#eef1f6', color: '#606266' }"
                >
                  <el-table-column prop="date" label="时间" sortable align="center"></el-table-column>
                  <el-table-column prop="name" label="接通率" sortable align="center"></el-table-column>
                  <el-table-column prop="name" label="通话时长" sortable align="center"></el-table-column>
                  <el-table-column prop="name" label="拨打次数" sortable align="center"></el-table-column>
                  <el-table-column prop="name" label="平均通话时长" sortable align="center"></el-table-column>
                  <el-table-column prop="name" label="联系人数" sortable align="center">
                    <template slot="header">
                      <span>联系人数</span>
                      <el-tooltip class="item" effect="dark" content="提示文字" placement="top"><i class="el-icon-question el-icon--right text-grey cursor" /></el-tooltip>
                    </template>
                  </el-table-column>
                </el-table>
              </div>
            </div>
          </div>
        </template>
        <template>
          <div v-show="salesIndex == 2">
            <div class="width-100 margin-top">
              <div class="flex width-100 border-grey aligin-items-center">
                <span class="bg-light-gray padding-sm text-center" style="width: 18%;">创建时间</span>
                <span class="padding-xs margin-left">
                  <span class="padding-xs margin-right border-radius bg-blue">今天</span>
                  <span class="padding-xs margin-right border-radius">昨天</span>
                  <span class="padding-xs margin-right border-radius">本周</span>
                  <span class="padding-xs margin-right border-radius">上周</span>
                  <span class="padding-xs margin-right border-radius">按月查看</span>
                </span>
              </div>
              <div class="flex width-100 border-grey aligin-items-center">
                <span class="bg-light-gray padding-sm text-center" style="width: 18%;">员工/部门</span>
                <span class="padding-xs margin-left">
                  <span class="padding-xs margin-right border-radius bg-blue">筛选</span>
                  <span class="padding-xs margin-right border-radius">不限</span>
                </span>
              </div>
            </div>
            <div class="margin-top padding border-grey">
              <div><span class="text-black text-bold text-lg">数据统计</span></div>
              <div class="margin-top-sm flex">
                <div class="flex1 border-right-grey padding">
                  <div class="flex aligin-items-center">
                    <span>新增微信客户(个)</span>
                    <el-tooltip class="item" effect="dark" content="提示文字" placement="top"><i class="el-icon-question el-icon--right text-grey cursor" /></el-tooltip>
                  </div>
                  <div class="text-orange text-bold margin-top" style="font-size: 30px;">0</div>
                </div>
                <div class="flex1 border-right-grey padding">
                  <div class="flex aligin-items-center">
                    <span>微信联系次数(次)</span>
                    <el-tooltip class="item" effect="dark" content="提示文字" placement="top"><i class="el-icon-question el-icon--right text-grey cursor" /></el-tooltip>
                  </div>
                  <div class="text-green text-bold margin-top" style="font-size: 30px;">0</div>
                </div>
                <div class="flex1 border-right-grey padding">
                  <div class="flex aligin-items-center">
                    <span>微信通话(次)</span>
                    <el-tooltip class="item" effect="dark" content="提示文字" placement="top"><i class="el-icon-question el-icon--right text-grey cursor" /></el-tooltip>
                  </div>
                  <div class="text-blue text-bold margin-top" style="font-size: 30px;">0</div>
                </div>
              </div>
            </div>
            <div class="margin-top padding border-grey">
              <span class="flex aligin-items-center"><span class="text-black text-bold text-lg">微信联系客户次数趋势</span></span>
              <div class="margin-top-sm tab-color"><echart width="100vh" :options="weChatOptions" id="weChatOptions"></echart></div>
            </div>
            <div class="margin-top padding border-grey">
              <span class="flex aligin-items-center"><span class="text-black text-bold text-lg">微信联系客户列表</span></span>
              <div class="margin-top-sm">
                <el-table :header-cell-style="{ background: '#eef1f6', color: '#606266' }" :data="customerData" style="width: 100%">
                  <el-table-column prop="name" align="center" label="姓名"></el-table-column>
                  <el-table-column prop="date" align="center" label="联系时间"></el-table-column>
                  <el-table-column prop="address" align="center" label="员工"></el-table-column>
                  <el-table-column prop="address" align="center" label="沟通摘要"></el-table-column>
                </el-table>
              </div>
            </div>
          </div>
        </template>
        <template>
          <div v-show="salesIndex == 3">
            <div class="width-100 margin-top">
              <div class="flex width-100 border-grey aligin-items-center">
                <span class="bg-light-gray padding-sm text-center" style="width: 18%;">员工/部门</span>
                <span class="padding-xs margin-left">
                  <span class="padding-xs margin-right border-radius bg-blue">筛选</span>
                  <span class="padding-xs margin-right border-radius">不限</span>
                </span>
              </div>
              <div class="flex width-100 border-grey aligin-items-center">
                <span class="bg-light-gray padding-sm text-center" style="width: 18%;">创建时间</span>
                <span class="padding-xs margin-left">
                  <span class="padding-xs margin-right border-radius bg-blue">今天</span>
                  <span class="padding-xs margin-right border-radius">昨天</span>
                  <span class="padding-xs margin-right border-radius">本周</span>
                  <span class="padding-xs margin-right border-radius">上周</span>
                  <span class="padding-xs margin-right border-radius">按月查看</span>
                </span>
              </div>
              <div class="flex width-100 border-grey aligin-items-center">
                <span class="bg-light-gray padding-sm text-center" style="width: 18%;">计划类型</span>
                <span class="padding-xs margin-left">
                  <span class="padding-xs margin-right border-radius bg-blue">全部</span>
                  <span class="padding-xs margin-right border-radius">电话</span>
                  <span class="padding-xs margin-right border-radius">短信</span>
                  <span class="padding-xs margin-right border-radius">QQ</span>
                  <span class="padding-xs margin-right border-radius">微信</span>
                </span>
              </div>
              <div class="flex width-100 border-grey aligin-items-center">
                <span class="bg-light-gray padding-sm text-center" style="width: 18%;">执行状态</span>
                <span class="padding-xs margin-left">
                  <span class="padding-xs margin-right border-radius bg-blue">全部</span>
                  <span class="padding-xs margin-right border-radius">已执行</span>
                  <span class="padding-xs margin-right border-radius">未执行</span>
                </span>
              </div>
            </div>
            <div class="margin-top-sm">
              <el-table :header-cell-style="{ background: '#eef1f6', color: '#606266' }" :data="customerData" style="width: 100%">
                <el-table-column prop="name" align="center" label="计划类型"></el-table-column>
                <el-table-column prop="date" align="center" label="计划名称"></el-table-column>
                <el-table-column prop="address" align="center" label="客户数量"></el-table-column>
                <el-table-column prop="address" align="center" label="执行状态"></el-table-column>
                <el-table-column prop="address" align="center" label="执行时间"></el-table-column>
                <el-table-column prop="address" align="center" label="执行人"></el-table-column>
                <el-table-column prop="address" align="center" label="计划详情"></el-table-column>
              </el-table>
            </div>
          </div>
        </template>
        <template>
          <div v-show="salesIndex == 4">
            <div class="width-100 margin-top">
              <div class="flex width-100 border-grey aligin-items-center">
                <span class="bg-light-gray padding-sm text-center" style="width: 18%;">员工/部门</span>
                <span class="padding-xs margin-left">
                  <span class="padding-xs margin-right border-radius bg-blue">筛选</span>
                  <span class="padding-xs margin-right border-radius">不限</span>
                </span>
              </div>
              <div class="flex width-100 border-grey aligin-items-center">
                <span class="bg-light-gray padding-sm text-center" style="width: 18%;">时间</span>
                <span class="padding-xs margin-left">
                  <span class="padding-xs margin-right border-radius bg-blue">今天</span>
                  <span class="padding-xs margin-right border-radius">昨天</span>
                  <span class="padding-xs margin-right border-radius">本周</span>
                  <span class="padding-xs margin-right border-radius">上周</span>
                  <span class="padding-xs margin-right border-radius">按月查看</span>
                </span>
                <el-divider direction="vertical"></el-divider>
                <el-date-picker size="mini" v-model="dateValue" type="daterange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期"></el-date-picker>
              </div>
            </div>
            <div class="margin-top padding border-grey">
              <div class="flex justify-content-between aligin-items-center">
                <span class="flex aligin-items-center">
                  <span class="text-black text-bold text-lg">趋势图</span>
                  <el-tooltip class="item" effect="dark" content="提示文字" placement="right"><i class="el-icon-question el-icon--right text-grey cursor" /></el-tooltip>
                </span>
              </div>
              <div class="margin-top-sm">
                 <el-radio-group v-model="hujiaoradio" size="mini">
                    <el-radio-button label="呼叫次数"></el-radio-button>
                    <el-radio-button label="无效号码检测率"></el-radio-button>
                  </el-radio-group>
              </div>
              <div class="margin-top-sm tab-color"><echart width="100vh" :options="dianOptions" id="dianOptions"></echart></div>
            </div>
            <div class="margin-top padding border-grey">
              <span class="flex aligin-items-center justify-content-between">
                <span class="text-black text-bold text-lg">电销卫士检测统计图</span>
                <el-button size="mini" icon="el-icon-download">导出数据</el-button>
              </span>
              <div class="margin-top-sm">
                <el-table :header-cell-style="{ background: '#eef1f6', color: '#606266' }" :data="customerData" style="width: 100%">
                  <el-table-column prop="date" align="center" label="联系时间"></el-table-column>
                  <el-table-column prop="name" align="center" label="呼出次数"></el-table-column>
                  <el-table-column prop="address" align="center" label="无效号码数"></el-table-column>
                  <el-table-column prop="address" align="center" label="无效号码拦截率"></el-table-column>
                  <el-table-column prop="address" align="center" label="通话意愿率低"></el-table-column>
                  <el-table-column prop="address" align="center" label="接通率低"></el-table-column>
                  <el-table-column prop="address" align="center" label="空号"></el-table-column>
                  <el-table-column prop="address" align="center" label="黑名单"></el-table-column>
                  <el-table-column prop="address" align="center" label="风险号码"></el-table-column>
                </el-table>
                
              </div>
              <span class="text-blue margin-top-sm">展示无数据时间</span>
            </div>
          </div>
        </template>
      </el-tab-pane>
      <el-tab-pane style="min-height: 87vh;">
        <span slot="label">
          <i class="el-icon-medal" />
          员工能力管理
        </span>
        <button-list :btnList="staffList" @btnClick="staffClick" />
        <template>
          <div v-show="onlineIndex == 0">
            <div class=" width-100 flex-wrap-wrap flex">
              <div class="border-right-grey padding-sm margin-top" v-for="item in phoneList" style="width: 25%;">
                <div class="padding-lr-sm">
                  <div class="flex justify-content-between">
                    <span>{{ item.name }}</span>
                    <span v-if="item.name == '座机在线数'" class="text-blue">还没话机？</span>
                  </div>
                  <div class="flex justify-content-between margin-top aligin-items-center">
                    <span class="text-bold" style="font-size: 30px;">{{ item.num }}</span>
                    <span>{{ item.percen }}%</span>
                  </div>
                </div>
              </div>
            </div>
            <div class="margin-top padding border-grey">
              <div class="flex justify-content-between aligin-items-center">
                <span class="flex aligin-items-center"><span class="text-black text-bold text-lg">数据详情</span></span>
              </div>
              <div class="margin-top-sm">
                <el-table
                  :data="customerData"
                  style="width: 100%"
                  :default-sort="{ prop: 'date', order: 'descending' }"
                  :header-cell-style="{ background: '#eef1f6', color: '#606266' }"
                >
                  <el-table-column prop="date" label="最近登陆时间" align="center"></el-table-column>
                  <el-table-column prop="name" label="近30天登录天数" align="center"></el-table-column>
                  <el-table-column prop="name" label="EC状态" align="center"></el-table-column>
                  <el-table-column prop="name" label="QQ状态" align="center"></el-table-column>
                  <el-table-column prop="name" label="座机状态" align="center"></el-table-column>
                </el-table>
                <span>注：</span>
              </div>
            </div>
          </div>
        </template>
        <template>
          <div v-show="onlineIndex == 1">
            <div class="width-100 margin-top">
              <div class="flex width-100 border-grey aligin-items-center">
                <span class="bg-light-gray padding-sm text-center" style="width: 18%;">员工/部门</span>
                <span class="padding-xs margin-left">
                  <span class="padding-xs margin-right border-radius bg-blue">筛选</span>
                  <span class="padding-xs margin-right border-radius">不限</span>
                </span>
              </div>
              <div class="flex width-100 border-grey aligin-items-center">
                <span class="bg-light-gray padding-sm text-center" style="width: 18%;">时间</span>
                <span class="padding-xs margin-left">
                  <span class="padding-xs margin-right border-radius bg-blue">今天</span>
                  <span class="padding-xs margin-right border-radius">昨天</span>
                  <span class="padding-xs margin-right border-radius">本周</span>
                  <span class="padding-xs margin-right border-radius">上周</span>
                  <span class="padding-xs margin-right border-radius">按月查看</span>
                </span>
                <el-divider direction="vertical"></el-divider>
                <el-date-picker size="mini" v-model="dateValue" type="daterange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期"></el-date-picker>
              </div>
            </div>
            <div class="margin-top padding border-grey">
              <div class="flex justify-content-between aligin-items-center">
                <span class="flex aligin-items-center">
                  <span class="text-black text-bold text-lg">排行榜</span>
                  <el-tooltip class="item" effect="dark" content="提示文字" placement="right"><i class="el-icon-question el-icon--right text-grey cursor" /></el-tooltip>
                </span>
              </div>
              <div class="margin-top-sm tab-color">
                <el-tabs v-model="activeName" @tab-click="handleClick">
                    <el-tab-pane label="接通率" name="first">
                      <el-table
                        :data="customerData"
                        style="width: 100%"
                        :default-sort="{ prop: 'date', order: 'descending' }"
                        :header-cell-style="{ background: '#eef1f6', color: '#606266' }"
                      >
                        <el-table-column prop="date" label="排名" sortable align="center"></el-table-column>
                        <el-table-column prop="name" label="员工" align="center"></el-table-column>
                        <el-table-column prop="name" label="接通率" sortable align="center"></el-table-column>
                      </el-table>
                    </el-tab-pane>
                    <el-tab-pane label="通话时长" name="second">配置管理</el-tab-pane>
                    <el-tab-pane label="拨打次数" name="third">角色管理</el-tab-pane>
                    <el-tab-pane label="平均通话时长" name="fourth">定时任务补偿</el-tab-pane>
                  </el-tabs>
              </div>
            </div>
            
            <div class="margin-top padding border-grey">
              <span class="flex aligin-items-center justify-content-between">
                <span class="text-black text-bold text-lg">电话联系统计表</span>
                <el-button size="mini" icon="el-icon-download">导出数据</el-button>
              </span>
              <div class="margin-top-sm">
                <el-table :header-cell-style="{ background: '#eef1f6', color: '#606266' }" :data="customerData" style="width: 100%">
                  <el-table-column prop="date" align="center" label="名称"></el-table-column>
                  <el-table-column prop="name" align="center" label="接通率"></el-table-column>
                  <el-table-column prop="address" align="center" label="通话时长"></el-table-column>
                  <el-table-column prop="address" align="center" label="拨打次数"></el-table-column>
                  <el-table-column prop="address" align="center" label="平均通话时长"></el-table-column>
                </el-table>
              </div>
            </div>
          </div>
        </template>
        <template>
          <div v-show="onlineIndex == 2">
            <div class="width-100 margin-top">
              <div class="flex width-100 border-grey aligin-items-center">
                <span class="bg-light-gray padding-sm text-center" style="width: 18%;">员工/部门</span>
                <span class="padding-xs margin-left">
                  <span class="padding-xs margin-right border-radius bg-blue">筛选</span>
                  <span class="padding-xs margin-right border-radius">不限</span>
                </span>
              </div>
              <div class="flex width-100 border-grey aligin-items-center">
                <span class="bg-light-gray padding-sm text-center" style="width: 18%;">时间</span>
                <span class="padding-xs margin-left">
                  <span class="padding-xs margin-right border-radius bg-blue">今天</span>
                  <span class="padding-xs margin-right border-radius">昨天</span>
                  <span class="padding-xs margin-right border-radius">本周</span>
                  <span class="padding-xs margin-right border-radius">上周</span>
                  <span class="padding-xs margin-right border-radius">按月查看</span>
                </span>
                <el-divider direction="vertical"></el-divider>
                <el-date-picker size="mini" v-model="dateValue" type="daterange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期"></el-date-picker>
              </div>
            </div>
            
            <div class="margin-top padding border-grey">
              <div>
                <span class="text-black text-bold text-lg">电话联系统计表</span>
              </div>
              <div class="margin-top-sm">
                <el-table :header-cell-style="{ background: '#eef1f6', color: '#606266' }" :data="customerData" style="width: 100%">
                  <el-table-column prop="date" align="center" sortable label="排名"></el-table-column>
                  <el-table-column prop="name" align="center" sortable label="员工/部门"></el-table-column>
                  <el-table-column prop="address" align="center" sortable label="访问量"></el-table-column>
                  <el-table-column prop="address" align="center" sortable label="访客量"></el-table-column>
                  <el-table-column prop="address" align="center" sortable label="转发量"></el-table-column>
                </el-table>
              </div>
            </div>
          </div>
        </template>
      </el-tab-pane>
      <el-tab-pane style="min-height: 87vh;">
        <span slot="label">
          <i class="el-icon-pie-chart" />
          销售业绩
        </span>
        <button-list :btnList="achievementList" @btnClick="achievementClick" />
        <template>
          <div v-show="dealIndex == 0">
            <div class="width-100 margin-top">
              <div class="flex width-100 border-grey aligin-items-center">
                <span class="bg-light-gray padding-sm text-center" style="width: 18%;">员工/部门</span>
                <span class="padding-xs margin-left">
                  <span class="padding-xs margin-right border-radius bg-blue">筛选</span>
                  <span class="padding-xs margin-right border-radius">不限</span>
                </span>
                <el-divider direction="vertical"></el-divider>
                <el-button type="primary" round size="mini">个人统计</el-button>
              </div>
              <div class="flex width-100 border-grey aligin-items-center">
                <span class="bg-light-gray padding-sm text-center" style="width: 18%;">时间</span>
                <span class="padding-xs margin-left">
                  <span class="padding-xs margin-right border-radius bg-blue">今天</span>
                  <span class="padding-xs margin-right border-radius">昨天</span>
                  <span class="padding-xs margin-right border-radius">本周</span>
                  <span class="padding-xs margin-right border-radius">上周</span>
                  <span class="padding-xs margin-right border-radius">按月查看</span>
                </span>
                <el-divider direction="vertical"></el-divider>
                <el-date-picker size="mini" v-model="dateValue" type="daterange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期"></el-date-picker>
              </div>
            </div>
            <div class="margin-top padding border-grey">
              <div class="flex justify-content-between aligin-items-center">
                <div>
                  <span class="text-black text-bold text-lg">成交走势图</span>
                </div>
              </div>
              <div class="margin-top-sm"><echart width="100vh" :options="dealOptions" id="dealOptions"></echart></div>
            </div>
            
            <div class="margin-top padding border-grey">
              <div>
                <span class="text-black text-bold text-lg">首页</span>
              </div>
              <div class="margin-top-sm">
                <el-table :header-cell-style="{ background: '#eef1f6', color: '#606266' }" :data="customerData" style="width: 100%">
                  <el-table-column prop="date" align="center" label="员工/部门"></el-table-column>
                  <el-table-column prop="name" align="center" sortable label="记录数"></el-table-column>
                  <el-table-column prop="address" align="center" sortable label="金额"></el-table-column>
                </el-table>
              </div>
            </div>
          </div>
        </template>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import ButtonList from './components/buttonList.vue';
import Echart from '@/components/echarts/Echart';
export default {
  components: {
    ButtonList,
    Echart
  },
  data() {
    return {
      userList: [
        {
          name: '客户数量分析',
          value: 0
        },
        {
          name: '客户活跃度分析',
          value: 1
        },
        {
          name: '标签变化分析',
          value: 2
        },
        {
          name: '客户创建记录',
          value: 3
        },
        {
          name: '渠道来源分析',
          value: 4
        },
        {
          name: '团队客户管理',
          value: 5
        },
        {
          name: '转化分析',
          value: 6
        }
      ],
      salesList: [
        {
          name: '工作效率分析',
          value: 0
        },
        {
          name: '电话联系分析',
          value: 1
        },
        {
          name: '微信联系分析',
          value: 2
        },
        {
          name: '销售计划记录',
          value: 3
        },
        {
          name: '电销卫士',
          value: 4
        }
      ],
      staffList: [
        {
          name: '员工在线状态',
          value: 0
        },
        {
          name: '电话排名',
          value: 1
        },
        {
          name: '名片排名',
          value: 2
        }
      ],
      achievementList: [
        {
          name: '成交走势统计',
          value: 0
        }
      ],
      // 客户数据分析
      userIndex: 0,
      timeList: [
        {
          value: '0',
          label: '全部'
        },
        {
          value: '1',
          label: '最近7天'
        },
        {
          value: '2',
          label: '今天'
        },
        {
          value: '3',
          label: '昨天'
        }
      ],
      timeValue: '0',
      options: [
        {
          value: '选项1',
          label: '黄金糕'
        },
        {
          value: '选项2',
          label: '双皮奶'
        },
        {
          value: '选项3',
          label: '蚵仔煎'
        }
      ],
      value: '',
      activeName: 'first',
      eOptions: {
        xAxis: {
          type: 'category',
          data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun']
        },
        yAxis: {
          type: 'value'
        },
        series: [
          {
            data: [150, 230, 224, 218, 135, 147, 260],
            type: 'line'
          }
        ]
      },
      checked: false,
      customerData: [
        {
          date: '2016-05-02',
          name: '王小虎',
          address: '上海市'
        },
        {
          date: '2016-05-04',
          name: '王小虎',
          address: '上海市普'
        },
        {
          date: '2016-05-01',
          name: '王小虎',
          address: '上海市'
        },
        {
          date: '2016-05-03',
          name: '王小虎',
          address: '上海市普'
        }
      ],
      // 客户活跃度分析
      customerOptions: {
        title: {
          text: '',
          subtext: '',
          left: 'center'
        },
        tooltip: {
          trigger: 'item'
        },
        legend: {
          orient: 'vertical',
          bottom: 'bottom'
        },
        series: [
          {
            name: '访问来源',
            type: 'pie',
            radius: '50%',
            center: ['50%', '50%'],
            data: [
              { value: 1048, name: '搜索引擎' },
              { value: 735, name: '直接访问' },
              { value: 580, name: '邮件营销' },
              { value: 484, name: '联盟广告' },
              { value: 300, name: '视频广告' }
            ],
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: 'rgba(0, 0, 0, 0.5)'
              }
            }
          }
        ]
      },

      // 客户创建记录
      input2: '',
      // 渠道来源分析
      dateValue: '',
      qudaoOptions: {
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          }
        },
        legend: {
          data: ['2011年', '2012年'],
          bottom: 'bottom'
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '8%',
          containLabel: true
        },
        xAxis: {
          type: 'value',
          boundaryGap: [0, 0.01]
        },
        yAxis: {
          type: 'category',
          data: ['巴西', '印尼', '美国', '印度', '中国', '世界人口(万)']
        },
        series: [
          {
            name: '2011年',
            type: 'bar',
            data: [18203, 23489, 29034, 104970, 131744, 630230]
          },
          {
            name: '2012年',
            type: 'bar',
            data: [19325, 23438, 31000, 121594, 134141, 681807]
          }
        ]
      },
      // 转化分析
      fromOptions: {
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          }
        },
        legend: {
          data: ['2011年', '2012年'],
          bottom: 'bottom'
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '8%',
          containLabel: true
        },
        xAxis: {
          type: 'value',
          boundaryGap: [0, 0.01]
        },
        yAxis: {
          type: 'category',
          data: ['巴西', '印尼', '美国', '印度', '中国', '世界人口(万)']
        },
        series: [
          {
            name: '2011年',
            type: 'bar',
            data: [18203, 23489, 29034, 104970, 131744, 630230]
          }
        ]
      },
      staffOptions: {
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          }
        },
        legend: {
          data: ['2011年', '2012年'],
          bottom: 'bottom'
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '8%',
          containLabel: true
        },
        xAxis: {
          type: 'value',
          boundaryGap: [0, 0.01]
        },
        yAxis: {
          type: 'category',
          data: ['巴西', '印尼', '美国', '印度', '中国', '世界人口(万)']
        },
        series: [
          {
            name: '2011年',
            type: 'bar',
            data: [18203, 23489, 29034, 104970, 131744, 630230]
          }
        ]
      },
      // 工作效率分析
      salesIndex: 0,
      // 电话联系分析
      dateTadio: '按日期查看',
      dateOptions: {
        xAxis: {
          type: 'category',
          data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun']
        },
        yAxis: {
          type: 'value'
        },
        series: [
          {
            data: [150, 230, 224, 218, 135, 147, 260],
            type: 'line'
          }
        ]
      },
      // 微信联系分析
      weChatOptions: {
        xAxis: {
          type: 'category',
          data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun']
        },
        yAxis: {
          type: 'value'
        },
        series: [
          {
            data: [150, 230, 224, 218, 135, 147, 260],
            type: 'line'
          }
        ]
      },
      // 电销卫士
      hujiaoradio: '呼叫次数',
      dianOptions: {
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'cross',
            crossStyle: {
              color: '#999'
            }
          }
        },
        legend: {
          data: ['百度', '谷歌'],
          icon: 'react', // 设置legend的图标样式
          top: 'bottom', // 设置legend的位置
          itemGap: 40 // 设置legend之间的间距
        },
        xAxis: [
          {
            type: 'category',
            data: ['1月', '2月', '3月', '4月', '5月', '6月', '7月', '8月', '9月', '10月', '11月', '12月'],
            axisPointer: {
              //设置指示器类型
              type: 'shadow'
            },
            axisTick: { show: false },
            axisLine: {
              lineStyle: {
                width: 2
              }
            }
          }
        ],
        yAxis: [
          {
            type: 'value',
            min: 0,
            max: 350,
            interval: 50,
            axisLine: {
              // 是否显示轴线
              show: false
            },
            axisTick: {
              // 是否显示刻度
              show: false
            }
          },
          {
            type: 'value',
            min: 0,
            max: 0.35,
            interval: 0.05,
            axisLabel: {
              formatter: '{value}%'
            },
            axisLine: {
              show: false
            },
            axisTick: {
              show: false
            }
          }
        ],
        series: [
          {
            name: '百度',
            type: 'bar',
            stack: '总量', // 数据堆叠，必须添加相同的stack属性
            data: [50, 60, 40, 50, 80, 120, 135, 200, 300, 180, 320, 200],
            itemStyle: {
              color: '#277eab'
            }
          },
          {
            name: '谷歌',
            type: 'bar',
            stack: '总量',
            data: [30, 20, 9, 26, 28, 70, 175, 90, 48, 18, 6, 30],
            itemStyle: {
              color: '#8bb4fb'
            }
          }
        ]
      },
      // 员工在线状态
      onlineIndex: 0,
      phoneList: [
        {
          name: '管理员工人数',
          num: '0',
          percen: '0'
        },
        {
          name: 'EC在线数',
          num: '0',
          percen: '0'
        },
        {
          name: 'QQ绑定数',
          num: '0',
          percen: '0'
        },
        {
          name: '座机在线数',
          num: '0%',
          percen: '0'
        }
      ],
      // 电话排名
      // 名片排名
      
      // 成交走势统计
      dealIndex: 0,
      dealOptions: {
        xAxis: {
          type: 'category',
          data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun']
        },
        yAxis: {
          type: 'value'
        },
        series: [
          {
            data: [150, 230, 224, 218, 135, 147, 260],
            type: 'line'
          }
        ]
      },
      
    };
  },
  created() {},
  mounted() {},
  methods: {
    userClick(index) {
      this.userIndex = index;
      this.$forceUpdate();
    },
    salesClick(index) {
      this.salesIndex = index;
    },
    staffClick(index) {
      this.onlineIndex = index;
    },
    achievementClick(index) {
      this.dealIndex = index;
    },
    // 工作效率分析表格过滤
    filterHandler(value, row, column) {
      const property = column['property'];
      return row[property] === value;
    }
  }
};
</script>

<style scoped>
.active-btn {
  background-color: #4a9efc;
  color: #ffffff;
  border: #4a9efc;
}
>>> .tab-color .el-tabs__item.is-active {
  color: #67c23a !important;
}
>>> .tab-color .el-tabs__active-bar {
  background-color: #67c23a !important;
}
>>> .tab-color .el-tabs__item:hover {
  color: #67c23a !important;
}
</style>
